// @file Global snackbar store
import currentUser from '@@/bits/current_user'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { SnackbarNotificationType } from '@@/enums'
import { uniqueId } from 'lodash-es'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface GlobalSnackbarNotification {
  notificationType?: SnackbarNotificationType
  message: string
  timeout?: number
  persist?: boolean
  dismissable?: boolean
  iconName?: string
  actionText?: string
  actionTextActions?: Function[]
  shouldTruncate?: boolean
}

// Internal type
interface QueuedGlobalSnackbarNotification extends GlobalSnackbarNotification {
  uid: string
  // The snackbar will occupy the originalIndex-th spot on the screen.
  // Since snackbars may be removed, a particular snackbar's position within snackbarArray might change.
  // In order to ensure snackbars don't shift around and don't overlap,
  // we assign a fixed originalIndex when it's originally/first added to the array.
  originalIndex: number
}

const DEFAULT_TIMEOUT = 3000

const snackbarNotificationTypePresets: Record<
  SnackbarNotificationType,
  { [P in keyof QueuedGlobalSnackbarNotification]?: QueuedGlobalSnackbarNotification[P] }
> = {
  [SnackbarNotificationType.error]: {
    iconName: 'exclamation',
  },
  [SnackbarNotificationType.success]: {
    iconName: 'checkmark',
  },
}

export const useGlobalSnackbarStore = defineStore('globalSnackbar', () => {
  const snackbarArray = ref([] as QueuedGlobalSnackbarNotification[])

  function setSnackbar(newSnackbar: GlobalSnackbarNotification): string {
    const uid = uniqueId('snack_')

    const originalIndex =
      snackbarArray.value.length === 0
        ? 0
        : Math.max(...snackbarArray.value.map((snackbar) => snackbar.originalIndex)) + 1

    const presetAttributes =
      newSnackbar.notificationType == null ? null : snackbarNotificationTypePresets[newSnackbar.notificationType]

    const newSnackbarNotification: QueuedGlobalSnackbarNotification = {
      ...newSnackbar,
      uid,
      originalIndex,
      ...presetAttributes,
      dismissable: presetAttributes?.dismissable ?? true,
    }

    snackbarArray.value = [...snackbarArray.value, newSnackbarNotification]

    // if user prefers to persist snackbars, return immediately
    const userAccessibilityInAppNotificationsPersisted =
      isAppUsing('autoDismissAppMessagesSetting') && currentUser.is_app_messages_auto_dismissed === false

    if (userAccessibilityInAppNotificationsPersisted || newSnackbar.persist === true) {
      return uid
    }

    window.setTimeout(() => {
      snackbarArray.value = snackbarArray.value.filter((snackbar) => snackbar.uid !== uid)
    }, newSnackbar.timeout ?? DEFAULT_TIMEOUT)

    return uid
  }

  function removeSnackbar(snackbarToRemoveUid: string): void {
    snackbarArray.value = snackbarArray.value.filter((snackbar) => snackbar.uid !== snackbarToRemoveUid)
  }

  function executeActions(actions: Function[] = [], event: Event): void {
    actions.forEach((fn): void => {
      if (typeof fn === 'function') {
        fn(event)
      }
    })
  }

  function removeAllSnackbars(): void {
    snackbarArray.value = []
  }

  function genericFetchError(): void {
    setSnackbar({
      message: __('Oops! Something went wrong. Please try again.'),
      notificationType: SnackbarNotificationType.error,
    })
  }

  function isMessageBeingShown(message): boolean {
    return snackbarArray.value.some((snackbar) => snackbar.message === message)
  }

  return {
    snackbarArray,
    setSnackbar,
    removeSnackbar,
    removeAllSnackbars,
    executeActions,
    genericFetchError,
    isMessageBeingShown,
  }
})

export { SnackbarNotificationType }
export type { QueuedGlobalSnackbarNotification }
